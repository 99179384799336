import { useEffect, useContext } from 'react'
import { signIn, useSession, signOut } from 'next-auth/react'
import { useRouter } from 'next/router'
import Head from 'next/head'
import Layout from 'mad-mint/layouts/legendofrocks/login'
import MadMintThemeContext from 'mad-mint/contexts/MadMint'
import useDetectWindowMode, {
  WINDOW_MODE,
} from 'mad-mint/hooks/useDetectWindowMode'

const handleSignIn = () => signIn()
const handleSignOut = () => signOut()

export default function Home() {
  const { theme } = useContext(MadMintThemeContext)
  const router = useRouter()
  const { data: session, status } = useSession()
  const windowMode = useDetectWindowMode()
  const isMobile = [WINDOW_MODE['XSM'], WINDOW_MODE['SM']].includes(windowMode)

  useEffect(() => {
    if (session) {
      if (session.user.roles?.includes('admin')) {
        router.push(`/admin`)
      } else if (session.user.roles?.includes('creator')) {
        router.push(`/creator`)
      } else {
        router.push(`/dashboard`)
      }
    }
  }, [session, router])

  return (
    <Layout page="landing">
      <Head>
        <title>Legends of Rock - Backstage</title>
      </Head>
      <div className="login-sceen">
        <div>
          <h1 className="text-white font-bold uppercase coming-section">
            LEGENDARY ROCK <br />
            <span className="gradient-color">COLLECTIBLES</span> ARE COMING
          </h1>
        </div>
        <div className="flex justify-between">
          <div className="description-section">
            <div className="text-white text-small coming-descripiton">
              Get your foot in the door of rock history! Unlock classic moments
              by collecting digital slides that allow you to own originals taken
              from the exclusive vault of Globe Photos.
            </div>
            <div>
              {status === 'loading' ? (
                <button className="button login disabled" disabled={true}>
                  <div>Loading...</div>
                </button>
              ) : status === 'authenticated' ? (
                <button className="button logout" onClick={handleSignOut}>
                  <span>Logout</span>
                </button>
              ) : (
                <button
                  className="button login"
                  onClick={handleSignIn}
                >
                  <span className="whitespace-nowrap">Join the waitlist</span>
                </button>
              )}
            </div>
          </div>
          <div className="nfts-image-section">
            <img
              src={
                isMobile
                  ? '/images/legendsofrock/mobile-user-card.png'
                  : '/images/legendsofrock/user-card.png'
              }
              alt="user-card"
              className="nft-image"
            />
          </div>
        </div>
      </div>
      <style jsx>{`
        .font-bold {
          font-family: 'tt trailers';
          font-style: normal;
          font-weight: 800;
          font-size: ${theme.sizes.largeHeading.h1.fontSize}px;
          line-height: ${theme.sizes.largeHeading.h1.lineHeight}px;
          text-transform: uppercase;
          color: ${theme.colors.bgPrimary};
        }
        .disabled {
          opacity: 0.4;
        }
        .button {
          border-radius: 8px;
          padding: 16px 24px;
          width: 180px;
          background: ${theme.colors.orange};
          color: ${theme.colors.lightBlack};
          font-size: ${theme.sizes.content.p4.fontSize}px;
          line-height: ${theme.sizes.content.p4.lineHeight}px;
          font-style: normal;
          font-weight: 500;
          font-family: 'Poppins-Regular';
        }
        .button span {
          letter-spacing: 0.02em;
          align-items: center;
          text-align: center;
        }
        .login-sceen {
          padding-top: 80px;
          margin-left: auto;
          margin-right: auto;
          justify-content: space-around;
          width: 1202px;
        }
        .text-small {
          font-size: ${theme.sizes.content.p4.fontSize}px;
          line-height: ${theme.sizes.content.p4.lineHeight}px;
          font-style: normal;
          font-weight: 400;
          font-family: 'Poppins-Regular';
        }
        .gradient-color {
          background: linear-gradient(
            to right,
            ${theme.colors.orange} 0%,
            ${theme.colors.yellow} 100%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .coming-section {
          white-space: nowrap;
        }
        .description-section {
          width: 50%;
        }
        .coming-descripiton {
          padding: 40px 0px;
          width: 621px;
        }
        .mobile-show {
          display: none;
        }
        @media screen and (max-width: 1920px) {
          .login-sceen {
            margin-left: 155px;
            margin-right: 155px;
            width: unset;
          }
        }
        @media screen and (max-width: 1439px) {
          .font-bold {
            font-size: ${theme.sizes.largeHeading.h2.fontSize}px;
            line-height: ${theme.sizes.largeHeading.h2.lineHeight}px;
          }
          .coming-descripiton {
            width: 370px;
          }
        }
        @media screen and (max-width: 1023px) {
          .login-sceen {
            padding-top: 40px;
            padding-bottom: 0px;
            margin-left: 75px;
            margin-right: 75px;
          }
          .font-bold {
            font-size: ${theme.sizes.largeHeading.h4.fontSize}px;
            line-height: ${theme.sizes.largeHeading.h4.lineHeight}px;
          }
          .button {
            width: 300px;
          }
          .nft-image {
            width: 250px;
          }
          .coming-descripiton {
            width: 310px;
          }
          .description-section {
            width: 70%;
          }
        }
        @media screen and (max-width: 767px) {
          .login-sceen {
            flex-direction: column;
            align-items: center;
            padding-top: 40px;
            margin-left: 40px;
            margin-right: 0px;
            position: relative;
          }
          .nfts-image-section {
            position: absolute;
            bottom: -120px;
            right: 0;
            width: 180px;
          }
          .button {
            width: 175px;
          }
          .coming-section {
            white-space: break-spaces;
          }
        }
        @media screen and (max-width: 639px) {
          .nfts-image-section {
            width: 150px;
          }
        }
      `}</style>
    </Layout>
  )
}
